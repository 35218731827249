@import "bootstrap/scss/bootstrap";

// Make the bootstrap icons available.
$bootstrap-icons-font-src: url("npm:bootstrap-icons/font/fonts/bootstrap-icons.woff2") format("woff2"),
url("npm:bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");
@import "bootstrap-icons/font/bootstrap-icons";

// Leave room for fixed-top navbar...
body {
    padding-top: 70px;
}

// ...and make sure it doesn't overlap when we scroll to anchors.
html {
    scroll-padding-top: 70px;
}


@media print {
    @page {
        padding: 0;
        margin: 2cm;
    }

    body {
        padding: 0;
    }

    $print-size: 12pt;

    html {
        font-size: $print-size;
    }

    .row {
        font-family: "Times";
        // font-family: "Helvetica";
        font-size: $print-size;
        text-align: justify;
        line-height: 1.2 * $print-size;

    }

    p {
        margin-bottom: .5 * $print-size;
    }

    li {
        margin-bottom: .25 * $print-size;
    }

    ul {
        padding-left: 1em;
    }

    h1 {
        font-size: 18pt;
        margin-top: 0;
    }

    h2 {
        font-size: 14pt;
        margin-top: 14pt;
    }

    h1,
    h2 {
        font-weight: bold;
        page-break-after: avoid;
    }

    a {
        text-decoration: inherit;
        color: inherit;
    }

    a[href]:after {
        content: none
    }
}
